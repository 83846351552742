class Clippy {
  init() {

    this.canvasElement = document.querySelector('.js-clippy-canvas');
    this.ctx = this.canvasElement.getContext('2d');
    this.imageURL = this.canvasElement.dataset['desktopImage'];
    if (!this.imageURL) {
      this.imageURL = this.canvasElement.dataset['mobileImage']
    }
    this.image = new Image();
    this.image.src = this.imageURL;
    this.canvasBounds = this.canvasElement.getBoundingClientRect();
    this.canvasElement.width = this.canvasBounds.width;
    this.canvasElement.height = this.canvasBounds.height;
    this.gradient = this.ctx.createLinearGradient(0, 0, 0, 300);
    this.gradient.addColorStop(0, 'rgba(0,0,0,1');
    this.gradient.addColorStop(.4, 'rgba(0,0,0,0)');
    this.gradient.addColorStop(1, 'rgba(0,212,255,0)');


    // this.gradient.addColorStop(0, 'red');
    // this.gradient.addColorStop(.35, 'green');
    // this.gradient.addColorStop(1, 'blue');

    if (this.image.loaded) {
      this.drawImage();
    } else {
      this.image.addEventListener("load", event => {
        this.drawImage();
        window.setTimeout(this.drawImage(), 500);
      });
      this.image.addEventListener("onload", event => {
        this.drawImage();
        window.setTimeout(this.drawImage(), 500);
      });
      window.setTimeout(() => {
        this.drawImage();
      }, 500);
    }
    this.reload();
    this.drawImage();

    window.addEventListener("resize", event => {
      window.requestAnimationFrame(() => {
        this.reload();
        this.drawImage();
      });
    })

  }

  reload() {
    this.canvasBounds = this.canvasElement.getBoundingClientRect();
    this.canvasElement.width = this.canvasBounds.width;
    this.canvasElement.height = this.canvasBounds.height;
  }

  drawImage() {

    let radiusRatio = .60;
    let horizontalOffset = (this.canvasBounds.width * .9);


    window.requestAnimationFrame(() => {
      this.ctx.fillStyle = '#8cbf56';
      this.ctx.beginPath();
      this.ctx.arc((this.canvasBounds.width * radiusRatio) * 1.1, (this.canvasBounds.height * .3), (this.canvasBounds.width * radiusRatio) + 16, 0, Math.PI * 2, true);
      this.ctx.fill();
      this.ctx.closePath();

      this.ctx.beginPath();
      this.ctx.arc((this.canvasBounds.width * radiusRatio) * 1.1, (this.canvasBounds.height * .3), this.canvasBounds.width * radiusRatio, 0, Math.PI * 2, true);
      this.ctx.clip();
      this.ctx.drawImage(this.image, 0, 0, this.image.height, this.image.height, 0, 0, this.canvasBounds.width, this.canvasBounds.height);
      // this.ctx.fillStyle = this.gradient;
      // this.ctx.fillRect(0, 0, 1000, 300);



    })

  }



}


window.addEventListener("DOMContentLoaded", () => {

  window.clippy = new Clippy();
  window.clippy.init();

});
